@tailwind base;
@tailwind components;
@tailwind utilities;
/* 
body {
  margin: 0;
  padding: 0;
  font-family: Helvetica, Arial, sans-serif;
  display: grid;
  background: #333e5a;
} */

/* a {
  color: #fff;
}

header {
  background: #1816b4;
  color: #fff;
  text-align: center;
}

main {
  min-height: 80vh;
  background: #fff;
}
main .controls {
  text-align: center;
  padding: 0.5em 0;
  background: #333e5a;
  color: #fff;
}
main canvas {
  padding: 20px;
  margin: 0 auto;
  display: block;
}
footer {
  background: #333e5a;
  color: #fff;
  text-align: center;
} */

/* .main {
  position: relative;
  z-index: 1;
  top: -220px;
  background: transparent;
} */

/* .homescreen{
  z-index: 1;
  background: #fff;
} */

/* .parent {
  position: relative;
}

.child {
  position: absolute;
  top: 20px;
  left: 20px;
} */



/* :root{
  --bgColor : hsla(242, 86%, 6%, 1);
  --bgColorLight : hsla(242, 86%, 24%, 1);
  --textColor : hsla(242, 86%, 88%, 1);
  --textColorDark : hsla(242, 36%, 0%, 1);
  --paperColor: hsla(242, 86%, 44%, 1);
  --paperColorDark: hsla(242, 86%, 34%, 1);
  --shadowColorFaint: hsla(0, 0%, 0%, 0.2);
}

::selected{
  color: var(--textColorDark);
} 



html, body{
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background: var(--bgColor);
  background: linear-gradient(135deg, var(--bgColor), var(--bgColorLight));
  color: var(--textColor);
  font-family: 'Saira', sans-serif;
  position: relative;
}

*{
  box-sizing: border-box;
  transition: all 0.12s cubic-bezier(0.42, 0.54, 0.22, 1.26);
}

#canvas {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

audio {
  position: fixed;
  left: 10px;
  bottom: -10px;
  width: calc(100% - 20px);
}

audio.active{
  bottom: 10px;
}

#thefile{
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: 1;
}

label.file{
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  padding: 1rem 2rem;
  border-radius: 4px;
  
  background: var(--paperColor);
  color: var(--textColor);
  font-size: 1.25em;
  font-weight: 700;
  box-shadow: 0 20px 60px var(--shadowColorFaint);

  cursor: pointer;
}


label.file:hover{
    background: var(--paperColorDark);
    transform: translate3d(-50%, -55%, 0);
}

label.file:active{
    background: var(--paperColorDark);
    transform: translate3d(-50%, -45%, 0);
}

label.file.normal{
  transform: translate3d(10%, 50%, 0);
  padding: 0.2rem 2rem;
  font-size: 1rem;
  top: 0;
  left: 0;
} */

.slide-pane{
  max-height: 400px;
  overflow: hidden;
  margin-top: 10%;
  border-radius: 10px;
}

.popper {
  z-index: 9999;
  max-width: 200px;
  padding: 10px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

[data-placement^="bottom"] > .popper {
  margin-top: 5px;
}

.arrow {
  width: 10px;
  height: 10px;
  position: absolute;
  transform: rotate(45deg);
  background-color: white;
}

[data-placement^="bottom"] > .arrow {
  top: -5px;
 
}